import * as XLSX from 'xlsx';
import { Collection } from '../types';

export function exportToExcel(collections: Collection[]) {
    const worksheet = XLSX.utils.json_to_sheet(collections);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Collections');
    XLSX.writeFile(workbook, 'collections.xlsx');
}

export function exportManifestToPDF(collection: Collection) {
    // In a real application, this would generate a PDF
    // For now, we'll just console.log the data
    console.log('Exporting manifest to PDF:', collection);
}