import { Collection } from '../types';
import { format } from 'date-fns';

export const mockCollections: Collection[] = [
    {
        id: '1',
        date: format(new Date(), 'yyyy-MM-dd'),
        time: '09:30',
        customerName: 'Acme Corp',
        bagsCollected: 12,
        binsCollected: 3,
        longitude: -1.2344,
        latitude: 51.7520,
        representativeName: 'John Smith',
        manifestNumber: 'MAN001',
    },
    {
        id: '2',
        date: format(new Date(), 'yyyy-MM-dd'),
        time: '14:15',
        customerName: 'TechCorp Ltd',
        bagsCollected: 8,
        binsCollected: 2,
        longitude: -1.2345,
        latitude: 51.7521,
        representativeName: 'Jane Doe',
        manifestNumber: 'MAN002',
    },
];