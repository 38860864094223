import React from 'react';
import { Search } from 'lucide-react';
import { debounce } from '../../utils/debounce';

interface CustomerFiltersProps {
    filters: {
        search: string;
        area: string;
        collectionCategory: string;
        weeklyCollections: string;
    };
    onFilterChange: (name: string, value: string) => void;
}

export function CustomerFilters({ filters, onFilterChange }: CustomerFiltersProps) {
    const debouncedSearch = debounce((value: string) => {
        onFilterChange('search', value);
    }, 300);

    return (
        <div className="bg-white p-4 rounded-lg shadow mb-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">
                        Search Customer
                    </label>
                    <div className="relative">
                        <input
                            type="text"
                            defaultValue={filters.search}
                            onChange={(e) => debouncedSearch(e.target.value)}
                            className="w-full p-2 pl-8 border rounded focus:ring-1 focus:ring-green-500"
                            placeholder="Search by name or account..."
                        />
                        <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
                    </div>
                </div>

                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">
                        Area
                    </label>
                    <select
                        value={filters.area}
                        onChange={(e) => onFilterChange('area', e.target.value)}
                        className="w-full p-2 border rounded focus:ring-1 focus:ring-green-500"
                    >
                        <option value="">All Areas</option>
                        <option value="North">North</option>
                        <option value="South">South</option>
                        <option value="East">East</option>
                        <option value="West">West</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">
                        Collection Category
                    </label>
                    <select
                        value={filters.collectionCategory}
                        onChange={(e) => onFilterChange('collectionCategory', e.target.value)}
                        className="w-full p-2 border rounded focus:ring-1 focus:ring-green-500"
                    >
                        <option value="">All Categories</option>
                        <option value="General Waste">General Waste</option>
                        <option value="Recyclables">Recyclables</option>
                        <option value="Organic">Organic</option>
                        <option value="Hazardous">Hazardous</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">
                        Weekly Collections
                    </label>
                    <select
                        value={filters.weeklyCollections}
                        onChange={(e) => onFilterChange('weeklyCollections', e.target.value)}
                        className="w-full p-2 border rounded focus:ring-1 focus:ring-green-500"
                    >
                        <option value="">All</option>
                        <option value="1">1 per week</option>
                        <option value="2">2 per week</option>
                        <option value="3">3 per week</option>
                        <option value="4">4+ per week</option>
                    </select>
                </div>
            </div>
        </div>
    );
}