import { Customer } from '../../types';

export const customers: Customer[] = [
    {"id": "COMM/BUSI/00101", "name": "Abacus", "accountNumber": "ACC0001", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00102", "name": "Acacia Park - Imbonini", "accountNumber": "ACC0002", "weeklyCollections": 3, "numberOfTenants": 31, "area": null, "collectionCategory": "Business", "minUnits": 31},
    {"id": "COMM/BUSI/00103", "name": "ACDC Complex", "accountNumber": "ACC0003", "weeklyCollections": 3, "numberOfTenants": 5, "area": "Ballito", "collectionCategory": "Business", "minUnits": 5},
    {"id": "COMM/BUSI/00104", "name": "Adam Park", "accountNumber": "ACC0004", "weeklyCollections": 3, "numberOfTenants": 10, "area": "Ballito", "collectionCategory": "Business", "minUnits": 10},
    {"id": "COMM/BUSI/00105", "name": "ADCA - Against Drugs and Child Abuse", "accountNumber": "ACC0005", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00106", "name": "Al Pescator", "accountNumber": "ACC0006", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00107", "name": "Alpha Security", "accountNumber": "ACC0007", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00108", "name": "Amatluli Park", "accountNumber": "ACC0008", "weeklyCollections": 3, "numberOfTenants": 7, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 7},
    {"id": "COMM/BUSI/00109", "name": "Anford Park", "accountNumber": "ACC0009", "weeklyCollections": 3, "numberOfTenants": 2, "area": "Ballito", "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00110", "name": "Ashley Park", "accountNumber": "ACC0010", "weeklyCollections": 3, "numberOfTenants": 12, "area": "Ballito", "collectionCategory": "Business", "minUnits": 12},
    {"id": "COMM/BUSI/00111", "name": "Ashton College", "accountNumber": "ACC0011", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00112", "name": "AVIS Van Rental", "accountNumber": "ACC0012", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00113", "name": "B.A.C Aviation", "accountNumber": "ACC0013", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00114", "name": "Ballito Accommodation", "accountNumber": "ACC0014", "weeklyCollections": 3, "numberOfTenants": 5, "area": "Ballito", "collectionCategory": "Business", "minUnits": 5},
    {"id": "COMM/BUSI/00115", "name": "Ballito Agencies", "accountNumber": "ACC0015", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00116", "name": "Ballito Auto Body", "accountNumber": "ACC0016", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00117", "name": "Ballito Business Centre - Gym", "accountNumber": "ACC0017", "weeklyCollections": 3, "numberOfTenants": 6, "area": "Ballito", "collectionCategory": "Business", "minUnits": 6},
    {"id": "COMM/BUSI/00118", "name": "Ballito Business Centre - Shiptech", "accountNumber": "ACC0018", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00119", "name": "Ballito Business Centre - Vet", "accountNumber": "ACC0019", "weeklyCollections": 3, "numberOfTenants": 7, "area": "Ballito", "collectionCategory": "Business", "minUnits": 7},
    {"id": "COMM/BUSI/00120", "name": "Ballito Business Park", "accountNumber": "ACC0020", "weeklyCollections": 3, "numberOfTenants": 6, "area": "Ballito", "collectionCategory": "Business", "minUnits": 6},
    {"id": "COMM/BUSI/00121", "name": "Ballito Buzz", "accountNumber": "ACC0021", "weeklyCollections": 3, "numberOfTenants": 2, "area": "Ballito", "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00122", "name": "Ballito Centre", "accountNumber": "ACC0022", "weeklyCollections": 3, "numberOfTenants": 25, "area": "Ballito", "collectionCategory": "Business", "minUnits": 25},
    {"id": "COMM/BUSI/00123", "name": "Ballito Clinic", "accountNumber": "ACC0023", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00124", "name": "Ballito Convenience Centre", "accountNumber": "ACC0024", "weeklyCollections": 3, "numberOfTenants": 4, "area": "Ballito", "collectionCategory": "Business", "minUnits": 4},
    {"id": "COMM/BUSI/00125", "name": "Ballito Fire Emergency Services", "accountNumber": "ACC0025", "weeklyCollections": 3, "numberOfTenants": 2, "area": "Ballito", "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00126", "name": "Ballito Information Centre", "accountNumber": "ACC0026", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00127", "name": "Ballito Junction Regional Mall", "accountNumber": "ACC0027", "weeklyCollections": 3, "numberOfTenants": 211, "area": "Ballito", "collectionCategory": "Business", "minUnits": 211},
    {"id": "COMM/BUSI/00128", "name": "Ballito Kennels and Cattery/Grooming Parlour", "accountNumber": "ACC0028", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00129", "name": "Ballito Lifestyle Centre", "accountNumber": "ACC0029", "weeklyCollections": 7, "numberOfTenants": 92, "area": "Ballito", "collectionCategory": "Business", "minUnits": 92},
    {"id": "COMM/BUSI/00130", "name": "Ballito Plant Hire", "accountNumber": "ACC0030", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00131", "name": "Ballito Self Storage", "accountNumber": "ACC0031", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00132", "name": "Ballito Steps", "accountNumber": "ACC0032", "weeklyCollections": 4, "numberOfTenants": 10, "area": "Ballito", "collectionCategory": "Business", "minUnits": 10},
    {"id": "COMM/BUSI/00133", "name": "Ballito Village", "accountNumber": "ACC0033", "weeklyCollections": 4, "numberOfTenants": 17, "area": "Ballito", "collectionCategory": "Business", "minUnits": 17},
    {"id": "COMM/BUSI/00134", "name": "Beaches Primary School", "accountNumber": "ACC0034", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00135", "name": "Big Orange - Storage Solutions", "accountNumber": "ACC0035", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00136", "name": "Bio Mek", "accountNumber": "ACC0036", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00137", "name": "Bloom Village", "accountNumber": "ACC0037", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00138", "name": "Blue Bay Furniture", "accountNumber": "ACC0038", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00139", "name": "Bluff Tyres", "accountNumber": "ACC0039", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00140", "name": "Bosch Diesel Centre", "accountNumber": "ACC0040", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00141", "name": "Boxer Meat Factory - Imbonini", "accountNumber": "ACC0041", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00142", "name": "BP Garage", "accountNumber": "ACC0043", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00143", "name": "BP Garage - Ballito CBD", "accountNumber": "ACC0042", "weeklyCollections": 3, "numberOfTenants": 4, "area": "Ballito", "collectionCategory": "Business", "minUnits": 4},
    {"id": "COMM/BUSI/00144", "name": "Brabham House", "accountNumber": "ACC0044", "weeklyCollections": 3, "numberOfTenants": 6, "area": "Ballito", "collectionCategory": "Business", "minUnits": 6},
    {"id": "COMM/BUSI/00145", "name": "Brooklands", "accountNumber": "ACC0045", "weeklyCollections": 3, "numberOfTenants": 22, "area": null, "collectionCategory": "Business", "minUnits": 22},
    {"id": "COMM/BUSI/00146", "name": "Builders Express", "accountNumber": "ACC0046", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00147", "name": "Builders Express - Ballito CBD", "accountNumber": "ACC0047", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00148", "name": "Burnedale Bistro", "accountNumber": "ACC0048", "weeklyCollections": 3, "numberOfTenants": 5, "area": null, "collectionCategory": "Business", "minUnits": 5},
    {"id": "COMM/BUSI/00149", "name": "Caltex Garage - Salt Rock", "accountNumber": "ACC0049", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00150", "name": "Canton Park", "accountNumber": "ACC0050", "weeklyCollections": 3, "numberOfTenants": 11, "area": null, "collectionCategory": "Business", "minUnits": 11},
    {"id": "COMM/BUSI/00151", "name": "Car Wash - Caltex Garage", "accountNumber": "ACC0051", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00152", "name": "Cash Crusaders", "accountNumber": "ACC0052", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00153", "name": "Centre Of Design", "accountNumber": "ACC0053", "weeklyCollections": 3, "numberOfTenants": 7, "area": "Ballito", "collectionCategory": "Business", "minUnits": 7},
    {"id": "COMM/BUSI/00154", "name": "Chairmans park", "accountNumber": "ACC0054", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00155", "name": "Chakas Rock Liquor", "accountNumber": "ACC0055", "weeklyCollections": 3, "numberOfTenants": 2, "area": "Shakas Rock", "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00156", "name": "Checkers - Ballito Junction Mall", "accountNumber": "ACC0057", "weeklyCollections": 7, "numberOfTenants": 2, "area": "Ballito", "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00157", "name": "Checkers - Mt Richmore Centre", "accountNumber": "ACC0056", "weeklyCollections": 7, "numberOfTenants": 2, "area": null, "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00158", "name": "Checkers Food & Liquor - Ballito Steps", "accountNumber": "ACC0058", "weeklyCollections": 7, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00159", "name": "Chery Motors Ballito", "accountNumber": "ACC0059", "weeklyCollections": 3, "numberOfTenants": 2, "area": null, "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00160", "name": "Chets Steel", "accountNumber": "ACC0060", "weeklyCollections": 3, "numberOfTenants": 4, "area": null, "collectionCategory": "Business", "minUnits": 4},
    {"id": "COMM/BUSI/00161", "name": "Chinook Spray Works - Labora Terra", "accountNumber": "ACC0133", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00162", "name": "Chriristian Revival Centre (CRC)", "accountNumber": "ACC0061", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00163", "name": "Cindys Fresh Produce", "accountNumber": "ACC0062", "weeklyCollections": 3, "numberOfTenants": 5, "area": null, "collectionCategory": "Business", "minUnits": 5},
    {"id": "COMM/BUSI/00164", "name": "Clemont Farm", "accountNumber": "ACC0063", "weeklyCollections": 3, "numberOfTenants": 4, "area": null, "collectionCategory": "Business", "minUnits": 4},
    {"id": "COMM/BUSI/00165", "name": "CMH Group Motor City", "accountNumber": "ACC0064", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00166", "name": "CO-Church", "accountNumber": "ACC0065", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00167", "name": "Costal Farmers", "accountNumber": "ACC0066", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00168", "name": "Country Kitchens", "accountNumber": "ACC0067", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00169", "name": "Crane Hire", "accountNumber": "ACC0068", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00170", "name": "Crankshaft & Cylinder Head Center", "accountNumber": "ACC0069", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00171", "name": "Creative Class Room", "accountNumber": "ACC0070", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00172", "name": "Crowthorne Corner", "accountNumber": "ACC0071", "weeklyCollections": 3, "numberOfTenants": 4, "area": "Ballito", "collectionCategory": "Business", "minUnits": 4},
    {"id": "COMM/BUSI/00173", "name": "CTM Complex", "accountNumber": "ACC0072", "weeklyCollections": 3, "numberOfTenants": 3, "area": "Ballito", "collectionCategory": "Business", "minUnits": 3},
    {"id": "COMM/BUSI/00174", "name": "DAAE Park", "accountNumber": "ACC0073", "weeklyCollections": 3, "numberOfTenants": 8, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 8},
    {"id": "COMM/BUSI/00175", "name": "Day Star Chickens", "accountNumber": "ACC0074", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00176", "name": "Deals on Wheels / Mc chloe", "accountNumber": "ACC0075", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00177", "name": "Deals on Wheels / Mc chloe", "accountNumber": "ACC0076", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00178", "name": "Dedicated Power cc", "accountNumber": "ACC0077", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00179", "name": "Dentist Oral Hygienist", "accountNumber": "ACC0078", "weeklyCollections": 3, "numberOfTenants": 3, "area": "Shakas Rock", "collectionCategory": "Business", "minUnits": 3},
    {"id": "COMM/BUSI/00180", "name": "Dolphin Coast Plant & Truck Hire - Labora Terra", "accountNumber": "ACC0138", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00181", "name": "Dolphin coast waste management workshop", "accountNumber": "ACC0079", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00182", "name": "Dolphin Holiday Resort", "accountNumber": "ACC0080", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00183", "name": "Dukuza Sub Station", "accountNumber": "ACC0081", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00184", "name": "Duncan Civils - Labora Terra", "accountNumber": "ACC0134", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00185", "name": "Dunlop Zone Ballito - Tyre Track", "accountNumber": "ACC0082", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00186", "name": "Eden Palms", "accountNumber": "ACC0083", "weeklyCollections": 3, "numberOfTenants": 10, "area": "Shakas Rock", "collectionCategory": "Business", "minUnits": 10},
    {"id": "COMM/BUSI/00187", "name": "Eden Village", "accountNumber": "ACC0084", "weeklyCollections": 3, "numberOfTenants": 8, "area": "Foxhill", "collectionCategory": "Business", "minUnits": 8},
    {"id": "COMM/BUSI/00188", "name": "Empty", "accountNumber": "ACC0085", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00189", "name": "empty", "accountNumber": "ACC0086", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00190", "name": "Ethekwini Cheese", "accountNumber": "ACC0087", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00191", "name": "Evco", "accountNumber": "ACC0088", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00192", "name": "Everfresh - Food Lovers Market", "accountNumber": "ACC0296", "weeklyCollections": 6, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00193", "name": "Ezee Tile", "accountNumber": "ACC0089", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00194", "name": "Farm serve", "accountNumber": "ACC0090", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00195", "name": "Fish Factory Salt rock Shopping Centre", "accountNumber": "ACC0091", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00196", "name": "Flomac Tanks", "accountNumber": "ACC0092", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00197", "name": "Food Lovers Market - The Circle", "accountNumber": "ACC0093", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00198", "name": "Form-Scaff Ballito - Labora Terra", "accountNumber": "ACC0135", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00199", "name": "Fresh Flowers", "accountNumber": "ACC0094", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00200", "name": "Fuel Depot", "accountNumber": "ACC0095", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00201", "name": "Glenart", "accountNumber": "ACC0096", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00202", "name": "govenders Bottle store", "accountNumber": "ACC0097", "weeklyCollections": 3, "numberOfTenants": 6, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 6},
    {"id": "COMM/BUSI/00203", "name": "Green Earth Landscapes", "accountNumber": "ACC0098", "weeklyCollections": 3, "numberOfTenants": 7, "area": null, "collectionCategory": "Business", "minUnits": 7},
    {"id": "COMM/BUSI/00204", "name": "Gregory Park", "accountNumber": "ACC0099", "weeklyCollections": 3, "numberOfTenants": 14, "area": "Ballito", "collectionCategory": "Business", "minUnits": 14},
    {"id": "COMM/BUSI/00205", "name": "Harcourts Real Estate", "accountNumber": "ACC0100", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00206", "name": "Haval Ballito & Alpine Cars", "accountNumber": "ACC0101", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00207", "name": "Hirschs", "accountNumber": "ACC0102", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00208", "name": "Home & Deco Centre", "accountNumber": "ACC0103", "weeklyCollections": 3, "numberOfTenants": 13, "area": "Ballito", "collectionCategory": "Business", "minUnits": 13},
    {"id": "COMM/BUSI/00209", "name": "Hops Restaurant and Bar", "accountNumber": "ACC0104", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00210", "name": "HP Steenkamp Attorneys", "accountNumber": "ACC0105", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00211", "name": "Hydro Wash", "accountNumber": "ACC0106", "weeklyCollections": 3, "numberOfTenants": 5, "area": "Ballito", "collectionCategory": "Business", "minUnits": 5},
    {"id": "COMM/BUSI/00212", "name": "Hyundai", "accountNumber": "ACC0107", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00213", "name": "Idladla", "accountNumber": "ACC0108", "weeklyCollections": 3, "numberOfTenants": 4, "area": "Idladla", "collectionCategory": "Business", "minUnits": 4},
    {"id": "COMM/BUSI/00214", "name": "Imbali Property", "accountNumber": "ACC0109", "weeklyCollections": 3, "numberOfTenants": 3, "area": "Ballito", "collectionCategory": "Business", "minUnits": 3},
    {"id": "COMM/BUSI/00215", "name": "Imbonini Mini Factories", "accountNumber": "ACC0110", "weeklyCollections": 3, "numberOfTenants": 17, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 17},
    {"id": "COMM/BUSI/00216", "name": "Impressive Signs", "accountNumber": "ACC0111", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00217", "name": "Impulse By The Sea", "accountNumber": "ACC0112", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00218", "name": "Ingenuity Park", "accountNumber": "ACC0113", "weeklyCollections": 3, "numberOfTenants": 7, "area": "Ballito", "collectionCategory": "Business", "minUnits": 7},
    {"id": "COMM/BUSI/00219", "name": "Isitimela Brick & Block", "accountNumber": "ACC0114", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00220", "name": "Ital Concrete/Inkunzi RMC - Labora Terra", "accountNumber": "ACC0130", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00221", "name": "Izulu Office Park", "accountNumber": "ACC0115", "weeklyCollections": 3, "numberOfTenants": 29, "area": "Ballito", "collectionCategory": "Business", "minUnits": 29},
    {"id": "COMM/BUSI/00222", "name": "Jesus Dominion International", "accountNumber": "ACC0116", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00223", "name": "JPB Electrical", "accountNumber": "ACC0117", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00224", "name": "Kanysa skin Star", "accountNumber": "ACC0118", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00225", "name": "Karibu-Pub", "accountNumber": "ACC0119", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00226", "name": "KFC Ballito", "accountNumber": "ACC0120", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00227", "name": "Khalishari Park", "accountNumber": "ACC0121", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00228", "name": "KIA", "accountNumber": "ACC0122", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00229", "name": "Koi Living", "accountNumber": "ACC0123", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00230", "name": "kudu park", "accountNumber": "ACC0124", "weeklyCollections": 3, "numberOfTenants": 8, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 8},
    {"id": "COMM/BUSI/00231", "name": "Kuta-Kola", "accountNumber": "ACC0125", "weeklyCollections": 4, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00232", "name": "Kyalami Park", "accountNumber": "ACC0126", "weeklyCollections": 3, "numberOfTenants": 30, "area": "Ballito", "collectionCategory": "Business", "minUnits": 30},
    {"id": "COMM/BUSI/00233", "name": "Kyra Park", "accountNumber": "ACC0127", "weeklyCollections": 3, "numberOfTenants": 18, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 18},
    {"id": "COMM/BUSI/00234", "name": "Labora Terra Industrial Park", "accountNumber": "ACC0128", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00235", "name": "Laguna Seca", "accountNumber": "ACC0140", "weeklyCollections": 3, "numberOfTenants": 42, "area": "Ballito Services Park & Bogmore", "collectionCategory": "Business", "minUnits": 42},
    {"id": "COMM/BUSI/00236", "name": "Lali Foods", "accountNumber": "ACC0141", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00237", "name": "Lemon Reef", "accountNumber": "ACC0142", "weeklyCollections": 3, "numberOfTenants": 21, "area": "Ballito", "collectionCategory": "Business", "minUnits": 21},
    {"id": "COMM/BUSI/00238", "name": "lifestyle Business Park", "accountNumber": "ACC0143", "weeklyCollections": 3, "numberOfTenants": 7, "area": "Ballito", "collectionCategory": "Business", "minUnits": 7},
    {"id": "COMM/BUSI/00239", "name": "Linc Compus", "accountNumber": "ACC0144", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Foxhill", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00240", "name": "Liquor Barn", "accountNumber": "ACC0145", "weeklyCollections": 3, "numberOfTenants": 2, "area": "Ballito", "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00241", "name": "Litchi Orchard", "accountNumber": "ACC0146", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00242", "name": "LMW Textiles", "accountNumber": "ACC0147", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00243", "name": "Lopac Tussues", "accountNumber": "ACC0148", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00244", "name": "Lorets Kitchen", "accountNumber": "ACC0149", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00245", "name": "Lozi Park", "accountNumber": "ACC0150", "weeklyCollections": 3, "numberOfTenants": 7, "area": "Ballito", "collectionCategory": "Business", "minUnits": 7},
    {"id": "COMM/BUSI/00246", "name": "Mac Mac Construction", "accountNumber": "ACC0151", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00247", "name": "Macphersons", "accountNumber": "ACC0152", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00248", "name": "Magnet Metal Merchants - Labora Terra", "accountNumber": "ACC0129", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00249", "name": "Mall 505", "accountNumber": "ACC0153", "weeklyCollections": 3, "numberOfTenants": 4, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 4},
    {"id": "COMM/BUSI/00250", "name": "Marshal World of Sport/Shave Paint Shop", "accountNumber": "ACC0154", "weeklyCollections": 3, "numberOfTenants": 5, "area": "Ballito", "collectionCategory": "Business", "minUnits": 5},
    {"id": "COMM/BUSI/00251", "name": "Mascor Complex", "accountNumber": "ACC0155", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00252", "name": "Mattioda Construction - Labora Terra", "accountNumber": "ACC0139", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00253", "name": "McDonalds", "accountNumber": "ACC0156", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00254", "name": "MCLAREN HOUSE", "accountNumber": "ACC0157", "weeklyCollections": 3, "numberOfTenants": 3, "area": "Ballito", "collectionCategory": "Business", "minUnits": 3},
    {"id": "COMM/BUSI/00255", "name": "MeadowBrook Park", "accountNumber": "ACC0158", "weeklyCollections": 3, "numberOfTenants": 3, "area": "Ballito", "collectionCategory": "Business", "minUnits": 3},
    {"id": "COMM/BUSI/00256", "name": "Mica BBS", "accountNumber": "ACC0159", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00257", "name": "Mono Block", "accountNumber": "ACC0160", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00258", "name": "Monza", "accountNumber": "ACC0161", "weeklyCollections": 3, "numberOfTenants": 13, "area": "Ballito", "collectionCategory": "Business", "minUnits": 13},
    {"id": "COMM/BUSI/00259", "name": "Mt Richmore School", "accountNumber": "ACC0162", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00260", "name": "Mt Richmore Shopping Centre", "accountNumber": "ACC0163", "weeklyCollections": 3, "numberOfTenants": 27, "area": null, "collectionCategory": "Business", "minUnits": 27},
    {"id": "COMM/BUSI/00261", "name": "Multi-Group", "accountNumber": "ACC0165", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00262", "name": "MXM Logistics", "accountNumber": "ACC0166", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00263", "name": "Mystic Car Sales", "accountNumber": "ACC0167", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00264", "name": "Nandos Lifestyle", "accountNumber": "ACC0168", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00265", "name": "National Motor Spares", "accountNumber": "ACC0169", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00266", "name": "Natures Unique Stones", "accountNumber": "ACC0170", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00267", "name": "NC Toilet Hire", "accountNumber": "ACC0171", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00268", "name": "Nickos Restaurant", "accountNumber": "ACC0172", "weeklyCollections": 7, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00269", "name": "Nkwazi Park", "accountNumber": "ACC0173", "weeklyCollections": 3, "numberOfTenants": 8, "area": "Ballito", "collectionCategory": "Business", "minUnits": 8},
    {"id": "COMM/BUSI/00270", "name": "Noble Oil & Energy", "accountNumber": "ACC0174", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00271", "name": "North Coast Fibre Glass", "accountNumber": "ACC0175", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00272", "name": "North Store South Storage", "accountNumber": "ACC0176", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00273", "name": "Nutrapharm", "accountNumber": "ACC0177", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00274", "name": "Nutriflo Park", "accountNumber": "ACC0178", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00275", "name": "Nyati Slabs - Labora Terra", "accountNumber": "ACC0132", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00276", "name": "Ocean Basket - Ballito", "accountNumber": "ACC0179", "weeklyCollections": 7, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00277", "name": "ORYX Park", "accountNumber": "ACC0180", "weeklyCollections": 3, "numberOfTenants": 6, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 6},
    {"id": "COMM/BUSI/00278", "name": "Owens TLB Hire - Labora Terra", "accountNumber": "ACC0137", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00279", "name": "Oyster Park", "accountNumber": "ACC0181", "weeklyCollections": 3, "numberOfTenants": 3, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 3},
    {"id": "COMM/BUSI/00280", "name": "Paws For Thought Kennals", "accountNumber": "ACC0182", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00281", "name": "Pentagon Court", "accountNumber": "ACC0183", "weeklyCollections": 3, "numberOfTenants": 12, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 12},
    {"id": "COMM/BUSI/00282", "name": "Pick n Pay Ballito Junction", "accountNumber": "ACC0184", "weeklyCollections": 3, "numberOfTenants": 3, "area": "Ballito", "collectionCategory": "Business", "minUnits": 3},
    {"id": "COMM/BUSI/00283", "name": "Pinnacle Park", "accountNumber": "ACC0185", "weeklyCollections": 3, "numberOfTenants": 20, "area": "Ballito", "collectionCategory": "Business", "minUnits": 20},
    {"id": "COMM/BUSI/00284", "name": "Platinum Park", "accountNumber": "ACC0186", "weeklyCollections": 3, "numberOfTenants": 17, "area": "Ballito", "collectionCategory": "Business", "minUnits": 17},
    {"id": "COMM/BUSI/00285", "name": "Posch Scaffolding", "accountNumber": "ACC0187", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00286", "name": "Preash Park", "accountNumber": "ACC0188", "weeklyCollections": 3, "numberOfTenants": 7, "area": "Ballito", "collectionCategory": "Business", "minUnits": 7},
    {"id": "COMM/BUSI/00287", "name": "Prestige Auto Panel beating & Spray Painting", "accountNumber": "ACC0189", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00288", "name": "Raddical Park", "accountNumber": "ACC0190", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00289", "name": "Reddam House", "accountNumber": "ACC0191", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00290", "name": "Reha Park", "accountNumber": "ACC0192", "weeklyCollections": 3, "numberOfTenants": 4, "area": "Ballito", "collectionCategory": "Business", "minUnits": 4},
    {"id": "COMM/BUSI/00291", "name": "Remax Ballito", "accountNumber": "ACC0193", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00292", "name": "Retain A Wall", "accountNumber": "ACC0194", "weeklyCollections": 3, "numberOfTenants": 2, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00293", "name": "Revive Medical Centre", "accountNumber": "ACC0195", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00294", "name": "Rey Park", "accountNumber": "ACC0196", "weeklyCollections": 3, "numberOfTenants": 15, "area": "Ballito", "collectionCategory": "Business", "minUnits": 15},
    {"id": "COMM/BUSI/00295", "name": "Richard Park", "accountNumber": "ACC0197", "weeklyCollections": 3, "numberOfTenants": 9, "area": "Ballito", "collectionCategory": "Business", "minUnits": 9},
    {"id": "COMM/BUSI/00296", "name": "Ringside Boxing", "accountNumber": "ACC0198", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00297", "name": "Rio Park", "accountNumber": "ACC0199", "weeklyCollections": 3, "numberOfTenants": 4, "area": "Ballito", "collectionCategory": "Business", "minUnits": 4},
    {"id": "COMM/BUSI/00298", "name": "Rivers Church", "accountNumber": "ACC0200", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00299", "name": "RMC INKUNZI", "accountNumber": "ACC0201", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00300", "name": "Roberts and chaplins", "accountNumber": "ACC0202", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00301", "name": "Rock Pots", "accountNumber": "ACC0203", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00302", "name": "Rockfield Trading", "accountNumber": "ACC0204", "weeklyCollections": 3, "numberOfTenants": 2, "area": null, "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00303", "name": "Rods Construction", "accountNumber": "ACC0205", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00304", "name": "S&J Electricial", "accountNumber": "ACC0206", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00305", "name": "Sage Centre", "accountNumber": "ACC0207", "weeklyCollections": 3, "numberOfTenants": 17, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 17},
    {"id": "COMM/BUSI/00306", "name": "Salmon Bay House", "accountNumber": "ACC0208", "weeklyCollections": 3, "numberOfTenants": 4, "area": "Ballito", "collectionCategory": "Business", "minUnits": 4},
    {"id": "COMM/BUSI/00307", "name": "Salt caf�", "accountNumber": "ACC0209", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Shakas Rock", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00308", "name": "Salt Rock Country Club", "accountNumber": "ACC0210", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00309", "name": "Salt Rock Shopping Centre", "accountNumber": "ACC0211", "weeklyCollections": 3, "numberOfTenants": 35, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 35},
    {"id": "COMM/BUSI/00310", "name": "Sandstone Story - Labora Terra", "accountNumber": "ACC0136", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00311", "name": "Sanhall Office Park", "accountNumber": "ACC0212", "weeklyCollections": 3, "numberOfTenants": 29, "area": "Ballito", "collectionCategory": "Business", "minUnits": 29},
    {"id": "COMM/BUSI/00312", "name": "Sasol Garage", "accountNumber": "ACC0213", "weeklyCollections": 3, "numberOfTenants": 3, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 3},
    {"id": "COMM/BUSI/00313", "name": "Seawood", "accountNumber": "ACC0214", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00314", "name": "Sebastian & Sons Shoes - Unlimited Shoe Repairs", "accountNumber": "ACC0215", "weeklyCollections": 3, "numberOfTenants": 2, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00315", "name": "second chance", "accountNumber": "ACC0216", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00316", "name": "Second Chance Clothing", "accountNumber": "ACC0217", "weeklyCollections": 3, "numberOfTenants": 2, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00317", "name": "Shakashead Industrial Park", "accountNumber": "ACC0218", "weeklyCollections": 3, "numberOfTenants": 11, "area": "Shakashead", "collectionCategory": "Business", "minUnits": 11},
    {"id": "COMM/BUSI/00318", "name": "Shell Garage Complex", "accountNumber": "ACC0219", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00319", "name": "Shiptech", "accountNumber": "ACC0220", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00320", "name": "Shukela Park", "accountNumber": "ACC0221", "weeklyCollections": 3, "numberOfTenants": 3, "area": null, "collectionCategory": "Business", "minUnits": 3},
    {"id": "COMM/BUSI/00321", "name": "Siggis Restaurant", "accountNumber": "ACC0222", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00322", "name": "SilverStone", "accountNumber": "ACC0223", "weeklyCollections": 3, "numberOfTenants": 20, "area": "Ballito", "collectionCategory": "Business", "minUnits": 20},
    {"id": "COMM/BUSI/00323", "name": "Simbithi Country Club", "accountNumber": "ACC0224", "weeklyCollections": 3, "numberOfTenants": 6, "area": null, "collectionCategory": "Business", "minUnits": 6},
    {"id": "COMM/BUSI/00324", "name": "Simbithi office park", "accountNumber": "ACC0225", "weeklyCollections": 3, "numberOfTenants": 35, "area": null, "collectionCategory": "Business", "minUnits": 35},
    {"id": "COMM/BUSI/00325", "name": "Sims Recycling", "accountNumber": "ACC0226", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00326", "name": "Siyakhula Mini Factories", "accountNumber": "ACC0227", "weeklyCollections": 3, "numberOfTenants": 3, "area": null, "collectionCategory": "Business", "minUnits": 3},
    {"id": "COMM/BUSI/00327", "name": "Siza Water Waste plant", "accountNumber": "ACC0228", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00328", "name": "Sizani School", "accountNumber": "ACC0229", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00329", "name": "SLS Towing", "accountNumber": "ACC0230", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00330", "name": "SMD BMW", "accountNumber": "ACC0231", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00331", "name": "Solaqua Holdings", "accountNumber": "ACC0232", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00332", "name": "Spar - Tiffanys Centre", "accountNumber": "ACC0233", "weeklyCollections": 4, "numberOfTenants": 1, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00333", "name": "SPCA", "accountNumber": "ACC0234", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00334", "name": "Sticks and Licks", "accountNumber": "ACC0235", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00335", "name": "Stones Complex", "accountNumber": "ACC0236", "weeklyCollections": 3, "numberOfTenants": 6, "area": "Ballito", "collectionCategory": "Business", "minUnits": 6},
    {"id": "COMM/BUSI/00336", "name": "Store it Tinley Manor", "accountNumber": "ACC0237", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00337", "name": "Store-it", "accountNumber": "ACC0238", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00338", "name": "Sugar Rush", "accountNumber": "ACC0239", "weeklyCollections": 3, "numberOfTenants": 14, "area": null, "collectionCategory": "Business", "minUnits": 14},
    {"id": "COMM/BUSI/00339", "name": "Sugar Village", "accountNumber": "ACC0240", "weeklyCollections": 3, "numberOfTenants": 12, "area": null, "collectionCategory": "Business", "minUnits": 12},
    {"id": "COMM/BUSI/00340", "name": "Super Cereal", "accountNumber": "ACC0241", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00341", "name": "Surf Riders", "accountNumber": "ACC0242", "weeklyCollections": 4, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00342", "name": "Swim Centre", "accountNumber": "ACC0243", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00343", "name": "Talisman Hire Umhlali", "accountNumber": "ACC0244", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00344", "name": "Tasos Bar & Grill", "accountNumber": "ACC0245", "weeklyCollections": 7, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00345", "name": "The Circle", "accountNumber": "ACC0246", "weeklyCollections": 3, "numberOfTenants": 24, "area": "Ballito", "collectionCategory": "Business", "minUnits": 24},
    {"id": "COMM/BUSI/00346", "name": "The Crest Business Park", "accountNumber": "ACC0247", "weeklyCollections": 3, "numberOfTenants": 25, "area": "Ballito", "collectionCategory": "Business", "minUnits": 25},
    {"id": "COMM/BUSI/00347", "name": "The Edge", "accountNumber": "ACC0248", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00348", "name": "The Educational Studio", "accountNumber": "ACC0249", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00349", "name": "The Makers Yard", "accountNumber": "ACC0250", "weeklyCollections": 3, "numberOfTenants": 13, "area": null, "collectionCategory": "Business", "minUnits": 13},
    {"id": "COMM/BUSI/00350", "name": "The Odyssey", "accountNumber": "ACC0251", "weeklyCollections": 7, "numberOfTenants": 21, "area": "Ballito", "collectionCategory": "Business", "minUnits": 21},
    {"id": "COMM/BUSI/00351", "name": "The Prominance windows and doors", "accountNumber": "ACC0252", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00352", "name": "The Quarter", "accountNumber": "ACC0253", "weeklyCollections": 3, "numberOfTenants": 25, "area": "Ballito", "collectionCategory": "Business", "minUnits": 25},
    {"id": "COMM/BUSI/00353", "name": "The Regency House", "accountNumber": "ACC0254", "weeklyCollections": 3, "numberOfTenants": 9, "area": "Ballito", "collectionCategory": "Business", "minUnits": 9},
    {"id": "COMM/BUSI/00354", "name": "The Well", "accountNumber": "ACC0255", "weeklyCollections": 4, "numberOfTenants": 17, "area": "Ballito", "collectionCategory": "Business", "minUnits": 17},
    {"id": "COMM/BUSI/00355", "name": "The Wilds", "accountNumber": "ACC0256", "weeklyCollections": 3, "numberOfTenants": 5, "area": "Ballito", "collectionCategory": "Business", "minUnits": 5},
    {"id": "COMM/BUSI/00356", "name": "The Work Space", "accountNumber": "ACC0257", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00357", "name": "The Yard", "accountNumber": "ACC0258", "weeklyCollections": 3, "numberOfTenants": 7, "area": null, "collectionCategory": "Business", "minUnits": 7},
    {"id": "COMM/BUSI/00358", "name": "Tiffanys Centre", "accountNumber": "ACC0259", "weeklyCollections": 7, "numberOfTenants": 31, "area": "Salt Rock", "collectionCategory": "Business", "minUnits": 31},
    {"id": "COMM/BUSI/00359", "name": "Tiger Wheel and Tyre", "accountNumber": "ACC0260", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00360", "name": "Tile Africa", "accountNumber": "ACC0261", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00361", "name": "TIRRELL HOUSE", "accountNumber": "ACC0262", "weeklyCollections": 3, "numberOfTenants": 5, "area": "Ballito", "collectionCategory": "Business", "minUnits": 5},
    {"id": "COMM/BUSI/00362", "name": "Total Energies Garage", "accountNumber": "ACC0263", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00363", "name": "Toyota dealership", "accountNumber": "ACC0264", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00364", "name": "Tyremart - The Circle", "accountNumber": "ACC0265", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00365", "name": "UBS - Unlimited Buliding Supplies", "accountNumber": "ACC0266", "weeklyCollections": 3, "numberOfTenants": 2, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00366", "name": "UCBD - UMHLALI COUNTY BUSINESS CENTRE", "accountNumber": "ACC0267", "weeklyCollections": 3, "numberOfTenants": 3, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 3},
    {"id": "COMM/BUSI/00367", "name": "Ultra Liquors", "accountNumber": "ACC0268", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00368", "name": "Umgeni Water", "accountNumber": "ACC0269", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00369", "name": "Umhlali Country Club", "accountNumber": "ACC0270", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00370", "name": "Umhlali Hotel Properties", "accountNumber": "ACC0271", "weeklyCollections": 3, "numberOfTenants": 2, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00371", "name": "Umhlali Methodist Church", "accountNumber": "ACC0272", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00372", "name": "Umhlali Plaza", "accountNumber": "ACC0273", "weeklyCollections": 3, "numberOfTenants": 5, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 5},
    {"id": "COMM/BUSI/00373", "name": "Umhlali Police Station", "accountNumber": "ACC0274", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00374", "name": "Umhlali Post Office", "accountNumber": "ACC0275", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00375", "name": "Umhlali Preparatory School", "accountNumber": "ACC0276", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00376", "name": "Umhlali Village", "accountNumber": "ACC0277", "weeklyCollections": 3, "numberOfTenants": 5, "area": null, "collectionCategory": "Business", "minUnits": 5},
    {"id": "COMM/BUSI/00377", "name": "Union Tiles", "accountNumber": "ACC0278", "weeklyCollections": 3, "numberOfTenants": 2, "area": "Ballito", "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00378", "name": "Uni-Span", "accountNumber": "ACC0279", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00379", "name": "Universal Ply", "accountNumber": "ACC0280", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00380", "name": "Valentie Centre", "accountNumber": "ACC0281", "weeklyCollections": 3, "numberOfTenants": 4, "area": "Ballito", "collectionCategory": "Business", "minUnits": 4},
    {"id": "COMM/BUSI/00381", "name": "Villa Commercial Park", "accountNumber": "ACC0282", "weeklyCollections": 3, "numberOfTenants": 4, "area": "Ballito", "collectionCategory": "Business", "minUnits": 4},
    {"id": "COMM/BUSI/00382", "name": "Virgin Active", "accountNumber": "ACC0283", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00383", "name": "VW Dealership", "accountNumber": "ACC0284", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00384", "name": "Warne House", "accountNumber": "ACC0285", "weeklyCollections": 3, "numberOfTenants": 27, "area": "Ballito", "collectionCategory": "Business", "minUnits": 27},
    {"id": "COMM/BUSI/00385", "name": "Wasser Cooling Tower", "accountNumber": "ACC0286", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00386", "name": "Water from Air", "accountNumber": "ACC0287", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00387", "name": "Waterberry Complex", "accountNumber": "ACC0288", "weeklyCollections": 3, "numberOfTenants": 2, "area": "Ballito", "collectionCategory": "Business", "minUnits": 2},
    {"id": "COMM/BUSI/00388", "name": "Westpoint Trading - Labora Terra", "accountNumber": "ACC0131", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00389", "name": "Winclo Panel Beaters", "accountNumber": "ACC0289", "weeklyCollections": 3, "numberOfTenants": 1, "area": "Imbonini Business Park", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00390", "name": "Wiseman Metals", "accountNumber": "ACC0290", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00391", "name": "Wooden Slabs", "accountNumber": "ACC0291", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00392", "name": "Woolworths - Mt Richmore Shopping Centre", "accountNumber": "ACC0164", "weeklyCollections": 3, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00393", "name": "Woolworths Ballito Junction", "accountNumber": "ACC0292", "weeklyCollections": 6, "numberOfTenants": 1, "area": "Ballito", "collectionCategory": "Business", "minUnits": 1},
    {"id": "COMM/BUSI/00394", "name": "WORK STATION", "accountNumber": "ACC0293", "weeklyCollections": 3, "numberOfTenants": 9, "area": "Umhlali", "collectionCategory": "Business", "minUnits": 9},
    {"id": "COMM/BUSI/00395", "name": "WOW Park", "accountNumber": "ACC0294", "weeklyCollections": 3, "numberOfTenants": 8, "area": null, "collectionCategory": "Business", "minUnits": 8},
    {"id": "COMM/BUSI/00396", "name": "Zimbali Wedge", "accountNumber": "ACC0295", "weeklyCollections": 7, "numberOfTenants": 1, "area": null, "collectionCategory": "Business", "minUnits": 1}
];

// Utility functions for filtering and searching
export const searchCustomers = (query: string): Customer[] => {
    const searchTerm = query.toLowerCase();
    return customers.filter(customer =>
        customer.name.toLowerCase().includes(searchTerm) ||
        customer.accountNumber.toLowerCase().includes(searchTerm) ||
        (customer.area && customer.area.toLowerCase().includes(searchTerm))
    );
};

export const filterCustomers = (filters: {
    area?: string;
    collectionCategory?: string;
    weeklyCollections?: number;
}): Customer[] => {
    return customers.filter(customer => {
        const matchesArea = !filters.area || customer.area === filters.area;
        const matchesCategory = !filters.collectionCategory ||
            customer.collectionCategory === filters.collectionCategory;
        const matchesCollections = !filters.weeklyCollections ||
            customer.weeklyCollections === filters.weeklyCollections;

        return matchesArea && matchesCategory && matchesCollections;
    });
};

export const getUniqueAreas = (): (string | null)[] => {
    const areas = new Set(customers.map(customer => customer.area).filter(Boolean));
    return Array.from(areas).sort();
};

export const getUniqueCollectionCategories = (): string[] => {
    const categories = new Set(customers.map(customer => customer.collectionCategory));
    return Array.from(categories).sort();
};

export const getCollectionFrequencies = (): number[] => {
    const frequencies = new Set(customers.map(customer => customer.weeklyCollections));
    return Array.from(frequencies).sort((a, b) => a - b);
};

export const getCustomerStats = () => {
    const totalCustomers = customers.length;
    const totalTenants = customers.reduce((sum, customer) => sum + customer.numberOfTenants, 0);
    const areaDistribution = customers.reduce((acc, customer) => {
        if (customer.area) {
            acc[customer.area] = (acc[customer.area] || 0) + 1;
        }
        return acc;
    }, {} as Record<string, number>);

    return {
        totalCustomers,
        totalTenants,
        areaDistribution,
        averageTenantsPerCustomer: totalTenants / totalCustomers
    };
};