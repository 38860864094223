import React from 'react';
import { Search } from 'lucide-react';
import { debounce } from '../../utils/debounce';

interface CollectionFiltersProps {
    filters: {
        dateRange: { start: string; end: string };
        category: string;
        collectionsPerWeek: string;
        customerName: string;
        manifestNumber: string;
    };
    onFilterChange: (name: string, value: string | { start: string; end: string }) => void;
}

export function CollectionFilters({ filters, onFilterChange }: CollectionFiltersProps) {
    const debouncedSearch = debounce((name: string, value: string) => {
        onFilterChange(name, value);
    }, 300);

    return (
        <div className="bg-white p-4 rounded-lg shadow mb-6 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">Date Range</label>
                    <div className="flex gap-2">
                        <input
                            type="date"
                            value={filters.dateRange.start}
                            onChange={(e) => onFilterChange('dateRange', { ...filters.dateRange, start: e.target.value })}
                            className="w-full p-2 border rounded focus:ring-1 focus:ring-green-500"
                        />
                        <input
                            type="date"
                            value={filters.dateRange.end}
                            onChange={(e) => onFilterChange('dateRange', { ...filters.dateRange, end: e.target.value })}
                            className="w-full p-2 border rounded focus:ring-1 focus:ring-green-500"
                        />
                    </div>
                </div>

                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">Category</label>
                    <select
                        value={filters.category}
                        onChange={(e) => onFilterChange('category', e.target.value)}
                        className="w-full p-2 border rounded focus:ring-1 focus:ring-green-500"
                    >
                        <option value="">All Categories</option>
                        <option value="general">General Waste</option>
                        <option value="recyclable">Recyclable</option>
                        <option value="organic">Organic</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">Collections per Week</label>
                    <select
                        value={filters.collectionsPerWeek}
                        onChange={(e) => onFilterChange('collectionsPerWeek', e.target.value)}
                        className="w-full p-2 border rounded focus:ring-1 focus:ring-green-500"
                    >
                        <option value="">All</option>
                        <option value="1">1 per week</option>
                        <option value="2">2 per week</option>
                        <option value="3">3 per week</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">Customer Name</label>
                    <div className="relative">
                        <input
                            type="text"
                            defaultValue={filters.customerName}
                            onChange={(e) => debouncedSearch('customerName', e.target.value)}
                            className="w-full p-2 pl-8 border rounded focus:ring-1 focus:ring-green-500"
                            placeholder="Search customer..."
                        />
                        <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
                    </div>
                </div>

                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">Manifest Number</label>
                    <input
                        type="text"
                        defaultValue={filters.manifestNumber}
                        onChange={(e) => debouncedSearch('manifestNumber', e.target.value)}
                        className="w-full p-2 border rounded focus:ring-1 focus:ring-green-500"
                        placeholder="Enter manifest number..."
                    />
                </div>
            </div>
        </div>
    );
}