import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Truck, Users, Building2, AlertCircle } from 'lucide-react';

const COLORS = ['#22C55E', '#3B82F6', '#EF4444', '#F59E0B'];

const collectionData = [
    { name: 'Mon', collections: 65 },
    { name: 'Tue', collections: 59 },
    { name: 'Wed', collections: 80 },
    { name: 'Thu', collections: 81 },
    { name: 'Fri', collections: 56 },
];

const categoryData = [
    { name: 'General Waste', value: 400 },
    { name: 'Recyclables', value: 300 },
    { name: 'Organic', value: 200 },
    { name: 'Hazardous', value: 100 },
];

export function DashboardPage() {
    return (
        <div className="space-y-6">
            <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="bg-white p-6 rounded-lg shadow">
                    <div className="flex items-center">
                        <Truck className="text-green-500 mr-4" size={24} />
                        <div>
                            <p className="text-sm font-bold text-gray-600">Total Collections</p>
                            <p className="text-2xl font-bold">341</p>
                        </div>
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg shadow">
                    <div className="flex items-center">
                        <Users className="text-blue-500 mr-4" size={24} />
                        <div>
                            <p className="text-sm font-bold text-gray-600">Active Customers</p>
                            <p className="text-2xl font-bold">127</p>
                        </div>
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg shadow">
                    <div className="flex items-center">
                        <Building2 className="text-red-500 mr-4" size={24} />
                        <div>
                            <p className="text-sm font-bold text-gray-600">Total Tenants</p>
                            <p className="text-2xl font-bold">892</p>
                        </div>
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg shadow">
                    <div className="flex items-center">
                        <AlertCircle className="text-yellow-500 mr-4" size={24} />
                        <div>
                            <p className="text-sm font-bold text-gray-600">Open Queries</p>
                            <p className="text-2xl font-bold">15</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-bold mb-4">Weekly Collections</h2>
                    <div className="h-80">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={collectionData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="collections" fill="#22C55E" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-bold mb-4">Collection Categories</h2>
                    <div className="h-80">
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={categoryData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {categoryData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}