import React, { useState } from 'react';

interface QueryFormProps {
    onSubmit: (query: {
        name: string;
        type: string;
        details: string;
    }) => void;
}

export function QueryForm({ onSubmit }: QueryFormProps) {
    const [formData, setFormData] = useState({
        name: '',
        type: '',
        details: '',
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
        setFormData({ name: '', type: '', details: '' });
    };

    return (
        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow p-6">
            <div className="space-y-4">
                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">
                        Your Name
                    </label>
                    <input
                        type="text"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        className="w-full p-2 border rounded focus:ring-1 focus:ring-green-500"
                        required
                    />
                </div>

                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">
                        Query Type
                    </label>
                    <select
                        value={formData.type}
                        onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                        className="w-full p-2 border rounded focus:ring-1 focus:ring-green-500"
                        required
                    >
                        <option value="">Select a type...</option>
                        <option value="Collection Issue">Collection Issue</option>
                        <option value="Service Request">Service Request</option>
                        <option value="Complaint">Complaint</option>
                        <option value="General Inquiry">General Inquiry</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">
                        Details
                    </label>
                    <textarea
                        value={formData.details}
                        onChange={(e) => setFormData({ ...formData, details: e.target.value })}
                        className="w-full p-2 border rounded focus:ring-1 focus:ring-green-500 h-32"
                        required
                    />
                </div>

                <button
                    type="submit"
                    className="w-full bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
                >
                    Submit Query
                </button>
            </div>
        </form>
    );
}