import React from 'react';
import { Search } from 'lucide-react';
import { debounce } from '../../utils/debounce';

interface TenantFiltersProps {
    filters: {
        customerName: string;
        status: string;
        search: string;
    };
    onFilterChange: (name: string, value: string) => void;
}

export function TenantFilters({ filters, onFilterChange }: TenantFiltersProps) {
    const debouncedSearch = debounce((value: string) => {
        onFilterChange('search', value);
    }, 300);

    return (
        <div className="bg-white p-4 rounded-lg shadow mb-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">
                        Search Tenant
                    </label>
                    <div className="relative">
                        <input
                            type="text"
                            defaultValue={filters.search}
                            onChange={(e) => debouncedSearch(e.target.value)}
                            className="w-full p-2 pl-8 border rounded focus:ring-1 focus:ring-green-500"
                            placeholder="Search by name..."
                        />
                        <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
                    </div>
                </div>

                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">
                        Customer
                    </label>
                    <select
                        value={filters.customerName}
                        onChange={(e) => onFilterChange('customerName', e.target.value)}
                        className="w-full p-2 border rounded focus:ring-1 focus:ring-green-500"
                    >
                        <option value="">All Customers</option>
                        <option value="Acme Corp">Acme Corp</option>
                        <option value="TechCorp Ltd">TechCorp Ltd</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-bold text-gray-700 mb-1">
                        Status
                    </label>
                    <select
                        value={filters.status}
                        onChange={(e) => onFilterChange('status', e.target.value)}
                        className="w-full p-2 border rounded focus:ring-1 focus:ring-green-500"
                    >
                        <option value="">All Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                </div>
            </div>
        </div>
    );
}