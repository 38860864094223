import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
    LayoutDashboard,
    Users,
    Truck,
    Building2,
    MessageSquare,
    BarChart3,
    ChevronLeft,
    ChevronRight,
    LogOut
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';

export function Sidebar() {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout().then(() => {
            navigate('/login');
        });
        navigate('/login');
    };

    const navItems = [
        { icon: LayoutDashboard, label: 'Dashboard', path: '/dashboard' },
        { icon: Users, label: 'Customers', path: '/customers' },
        { icon: Truck, label: 'Collections', path: '/collections' },
        { icon: Building2, label: 'Tenants', path: '/tenants' },
        { icon: MessageSquare, label: 'Queries', path: '/queries' },
        { icon: BarChart3, label: 'Analytics', path: '/analytics' },
    ];

    return (
        <div className={`bg-gray-900 text-white transition-all duration-300 flex flex-col h-full ${isCollapsed ? 'w-16' : 'w-64'}`}>
            <div className="p-4 flex items-center justify-between">
                {!isCollapsed && <h2 className="text-xl font-bold">KDM Portal</h2>}
                <button
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    className="p-2 hover:bg-gray-800 rounded"
                >
                    {isCollapsed ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
                </button>
            </div>

            <nav className="flex-1">
                {navItems.map((item) => (
                    <NavLink
                        key={item.path}
                        to={item.path}
                        className={({ isActive }) =>
                            `flex items-center p-4 hover:bg-gray-800 ${
                                isActive ? 'bg-gray-800' : ''
                            }`
                        }
                    >
                        <item.icon size={20} />
                        {!isCollapsed && <span className="ml-4 font-bold">{item.label}</span>}
                    </NavLink>
                ))}
            </nav>

            <button
                onClick={handleLogout}
                className="flex items-center p-4 hover:bg-gray-800 mt-auto border-t border-gray-800"
            >
                <LogOut size={20} />
                {!isCollapsed && <span className="ml-4 font-bold">Logout</span>}
            </button>
        </div>
    );
}