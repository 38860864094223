import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { Layout } from './components/layout/Layout';
import { LoginPage } from './pages/LoginPage';
import { DashboardPage } from './pages/DashboardPage';
import { CustomersPage } from './pages/CustomersPage';
import { CollectionsPage } from './pages/CollectionsPage';
import { TenantsPage } from './pages/TenantsPage';
import { QueriesPage } from './pages/QueriesPage';
import { AnalyticsPage } from './pages/AnalyticsPage';
import { PrivateRoute } from './components/PrivateRoute';

function App() {
  return (
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route element={<PrivateRoute><Layout /></PrivateRoute>}>
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/customers" element={<CustomersPage />} />
              <Route path="/collections" element={<CollectionsPage />} />
              <Route path="/tenants" element={<TenantsPage />} />
              <Route path="/queries" element={<QueriesPage />} />
              <Route path="/analytics" element={<AnalyticsPage />} />
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
  );
}

export default App;