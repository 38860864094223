import { Query } from '../types';
import { format } from 'date-fns';

export const mockQueries: Query[] = [
    {
        id: '1',
        name: 'John Smith',
        type: 'Collection Issue',
        details: 'Missed collection at Office Building A',
        date: format(new Date(), 'yyyy-MM-dd'),
        status: 'pending',
    },
    {
        id: '2',
        name: 'Jane Doe',
        type: 'Service Request',
        details: 'Request for additional bin',
        date: format(new Date(), 'yyyy-MM-dd'),
        status: 'resolved',
    },
];