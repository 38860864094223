import React, { useState, useMemo } from 'react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
} from '@tanstack/react-table';
import { Tenant } from '../types';
import { mockTenants } from '../utils/mockTenants';
import { TenantFilters } from '../components/tenants/TenantFilters';
import { StatusBadge } from '../components/tenants/StatusBadge';

const columnHelper = createColumnHelper<Tenant>();

const columns = [
    columnHelper.accessor('name', {
        header: 'Tenant Name',
        cell: info => <span className="font-bold">{info.getValue()}</span>,
    }),
    columnHelper.accessor('customerId', {
        header: 'Customer',
        cell: info => (
            <span className="font-bold">
        {info.getValue() === '1' ? 'Acme Corp' : 'TechCorp Ltd'}
      </span>
        ),
    }),
    columnHelper.accessor('isActive', {
        header: 'Status',
        cell: info => <StatusBadge isActive={info.getValue()} />,
    }),
];

export function TenantsPage() {
    const [filters, setFilters] = useState({
        customerName: '',
        status: '',
        search: '',
    });

    const filteredData = useMemo(() => {
        return mockTenants.filter(tenant => {
            const matchesSearch = !filters.search
                ? true
                : tenant.name.toLowerCase().includes(filters.search.toLowerCase());

            const matchesCustomer = !filters.customerName
                ? true
                : (tenant.customerId === '1' && filters.customerName === 'Acme Corp') ||
                (tenant.customerId === '2' && filters.customerName === 'TechCorp Ltd');

            const matchesStatus = !filters.status
                ? true
                : (tenant.isActive && filters.status === 'active') ||
                (!tenant.isActive && filters.status === 'inactive');

            return matchesSearch && matchesCustomer && matchesStatus;
        });
    }, [filters]);

    const handleFilterChange = (name: string, value: string) => {
        setFilters(prev => ({ ...prev, [name]: value }));
    };

    const table = useReactTable({
        data: filteredData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <div className="space-y-6">
            <h1 className="text-2xl font-bold text-gray-900">Tenants</h1>

            <TenantFilters filters={filters} onFilterChange={handleFilterChange} />

            <div className="bg-white rounded-lg shadow overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th
                                    key={header.id}
                                    className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id} className="hover:bg-gray-50">
                            {row.getVisibleCells().map(cell => (
                                <td
                                    key={cell.id}
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}