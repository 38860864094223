import React, { useState } from 'react';
import { QueryForm } from '../components/queries/QueryForm';
import { QueryTable } from '../components/queries/QueryTable';
import { mockQueries } from '../utils/mockQueries';
import { Query } from '../types';
import { format } from 'date-fns';

export function QueriesPage() {
    const [queries, setQueries] = useState<Query[]>(mockQueries);

    const handleSubmitQuery = (formData: {
        name: string;
        type: string;
        details: string;
    }) => {
        const newQuery: Query = {
            id: (queries.length + 1).toString(),
            date: format(new Date(), 'yyyy-MM-dd'),
            status: 'pending',
            ...formData,
        };
        setQueries([newQuery, ...queries]);
    };

    return (
        <div className="space-y-6">
            <h1 className="text-2xl font-bold text-gray-900">Queries</h1>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                <div className="lg:col-span-1">
                    <h2 className="text-xl font-bold text-gray-900 mb-4">Submit Query</h2>
                    <QueryForm onSubmit={handleSubmitQuery} />
                </div>

                <div className="lg:col-span-2">
                    <h2 className="text-xl font-bold text-gray-900 mb-4">Recent Queries</h2>
                    <QueryTable queries={queries} />
                </div>
            </div>
        </div>
    );
}