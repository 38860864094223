import { Customer } from '../types';

export const mockCustomers: Customer[] = [
    {
        id: '1',
        name: 'Acme Corp',
        accountNumber: 'ACC001',
        weeklyCollections: 3,
        numberOfTenants: 12,
        area: 'North',
        collectionCategory: 'General Waste',
        minUnits: 100,
    },
    {
        id: '2',
        name: 'TechCorp Ltd',
        accountNumber: 'ACC002',
        weeklyCollections: 2,
        numberOfTenants: 8,
        area: 'South',
        collectionCategory: 'Recyclables',
        minUnits: 50,
    },
    {
        id: '3',
        name: 'Green Solutions',
        accountNumber: 'ACC003',
        weeklyCollections: 4,
        numberOfTenants: 15,
        area: 'East',
        collectionCategory: 'Organic',
        minUnits: 75,
    },
    {
        id: '4',
        name: 'Industrial Systems',
        accountNumber: 'ACC004',
        weeklyCollections: 1,
        numberOfTenants: 5,
        area: 'West',
        collectionCategory: 'Hazardous',
        minUnits: 25,
    },
];