import React, { useState } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
    LineChart,
    Line,
    Legend,
} from 'recharts';
import { mockCollections } from '../utils/mockData';
import { mockCustomers } from '../utils/mockCustomers';
import { Download } from 'lucide-react';

const COLORS = ['#22C55E', '#3B82F6', '#EF4444', '#F59E0B'];

// Calculate collections by area
const collectionsByArea = mockCustomers.reduce((acc, customer) => {
    if (customer.area) {
        acc[customer.area] = (acc[customer.area] || 0) + customer.weeklyCollections;
    }
    return acc;
}, {} as Record<string, number>);

const areaData = Object.entries(collectionsByArea).map(([name, value]) => ({
    name,
    value,
}));

// Calculate collections by category
const categoryData = mockCustomers.reduce((acc, customer) => {
    acc[customer.collectionCategory] = (acc[customer.collectionCategory] || 0) + 1;
    return acc;
}, {} as Record<string, number>);

const wasteData = Object.entries(categoryData).map(([name, value]) => ({
    name,
    value,
}));

// Monthly trend data (mock data)
const monthlyTrendData = [
    { month: 'Jan', collections: 420 },
    { month: 'Feb', collections: 380 },
    { month: 'Mar', collections: 450 },
    { month: 'Apr', collections: 410 },
    { month: 'May', collections: 440 },
    { month: 'Jun', collections: 480 },
];

// Daily distribution data
const dailyDistributionData = [
    { day: 'Mon', collections: 95 },
    { day: 'Tue', collections: 85 },
    { day: 'Wed', collections: 100 },
    { day: 'Thu', collections: 90 },
    { day: 'Fri', collections: 80 },
];

export function AnalyticsPage() {
    const [timeframe, setTimeframe] = useState('week');

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold text-gray-900">Analytics</h1>
                <div className="flex gap-4">
                    <select
                        value={timeframe}
                        onChange={(e) => setTimeframe(e.target.value)}
                        className="p-2 border rounded focus:ring-1 focus:ring-green-500"
                    >
                        <option value="week">Last Week</option>
                        <option value="month">Last Month</option>
                        <option value="quarter">Last Quarter</option>
                        <option value="year">Last Year</option>
                    </select>
                    <button
                        className="flex items-center gap-2 bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
                    >
                        <Download size={20} />
                        Export Report
                    </button>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Collections by Area */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-bold mb-4">Collections by Area</h2>
                    <div className="h-80">
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={areaData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                    label={({ name, value }) => `${name}: ${value}`}
                                >
                                    {areaData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* Waste Categories Distribution */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-bold mb-4">Waste Categories Distribution</h2>
                    <div className="h-80">
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={wasteData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                    label={({ name, value }) => `${name}: ${value}`}
                                >
                                    {wasteData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* Monthly Collection Trends */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-bold mb-4">Collection Trends</h2>
                    <div className="h-80">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={monthlyTrendData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Line
                                    type="monotone"
                                    dataKey="collections"
                                    stroke="#22C55E"
                                    strokeWidth={2}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* Daily Distribution */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-bold mb-4">Daily Distribution</h2>
                    <div className="h-80">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={dailyDistributionData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="day" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="collections" fill="#22C55E" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>

            {/* Statistical Summary */}
            <div className="bg-white p-6 rounded-lg shadow">
                <h2 className="text-xl font-bold mb-4">Statistical Summary</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                        <p className="text-sm font-bold text-gray-600">Total Collections</p>
                        <p className="text-2xl font-bold text-green-600">2,345</p>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                        <p className="text-sm font-bold text-gray-600">Average Daily Collections</p>
                        <p className="text-2xl font-bold text-green-600">89</p>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                        <p className="text-sm font-bold text-gray-600">Most Active Area</p>
                        <p className="text-2xl font-bold text-green-600">North</p>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                        <p className="text-sm font-bold text-gray-600">Peak Collection Day</p>
                        <p className="text-2xl font-bold text-green-600">Wednesday</p>
                    </div>
                </div>
            </div>
        </div>
    );
}