import React from 'react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { Query } from '../../types';
import { format } from 'date-fns';

const columnHelper = createColumnHelper<Query>();

const columns = [
    columnHelper.accessor('date', {
        header: 'Date',
        cell: info => (
            <span className="font-bold">
        {format(new Date(info.getValue()), 'MMM dd, yyyy')}
      </span>
        ),
    }),
    columnHelper.accessor('name', {
        header: 'Name',
        cell: info => <span className="font-bold">{info.getValue()}</span>,
    }),
    columnHelper.accessor('type', {
        header: 'Type',
        cell: info => <span className="font-bold">{info.getValue()}</span>,
    }),
    columnHelper.accessor('details', {
        header: 'Details',
        cell: info => <span className="font-bold">{info.getValue()}</span>,
    }),
    columnHelper.accessor('status', {
        header: 'Status',
        cell: info => (
            <span
                className={`font-bold px-3 py-1 rounded-full text-sm ${
                    info.getValue() === 'pending'
                        ? 'bg-yellow-100 text-yellow-800'
                        : 'bg-green-100 text-green-800'
                }`}
            >
        {info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1)}
      </span>
        ),
    }),
];

interface QueryTableProps {
    queries: Query[];
}

export function QueryTable({ queries }: QueryTableProps) {
    const table = useReactTable({
        data: queries,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="bg-white rounded-lg shadow overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th
                                key={header.id}
                                className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                            >
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id} className="hover:bg-gray-50">
                        {row.getVisibleCells().map(cell => (
                            <td
                                key={cell.id}
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}