import React, { useState, useMemo } from 'react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
    SortingState,
} from '@tanstack/react-table';
import { ChevronDown, ChevronRight, ArrowUpDown, ArrowUp, ArrowDown } from 'lucide-react';
import { Customer } from '../types';
import { customerService } from '../services/data/customerService';
import { CustomerFilters } from '../components/customers/CustomerFilters';
import { StatusBadge } from '../components/tenants/StatusBadge';

const columnHelper = createColumnHelper<Customer>();

export function CustomersPage() {
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
    const [sorting, setSorting] = useState<SortingState>([]);
    const [filters, setFilters] = useState({
        search: '',
        area: '',
        collectionCategory: '',
        weeklyCollections: '',
    });

    const customers = customerService.getCustomers();

    const toggleRow = (customerId: string) => {
        const newExpandedRows = new Set(expandedRows);
        if (expandedRows.has(customerId)) {
            newExpandedRows.delete(customerId);
        } else {
            newExpandedRows.add(customerId);
        }
        setExpandedRows(newExpandedRows);
    };

    const columns = [
        columnHelper.display({
            id: 'expander',
            cell: ({ row }) => (
                <button
                    onClick={() => toggleRow(row.original.id)}
                    className="p-2 hover:bg-green-50 rounded-full transition-colors duration-150"
                >
                    {expandedRows.has(row.original.id) ? (
                        <ChevronDown size={20} className="text-green-600" />
                    ) : (
                        <ChevronRight size={20} className="text-green-600" />
                    )}
                </button>
            ),
            size: 40,
        }),
        columnHelper.accessor('name', {
            header: ({ column }) => (
                <button
                    className="flex items-center gap-2 hover:text-green-100 transition-colors duration-150 group w-full text-white text-base"
                    onClick={() => column.toggleSorting()}
                >
                    Customer Name
                    <span className="text-gray-300 group-hover:text-white">
            {column.getIsSorted() === false ? (
                <ArrowUpDown size={16} />
            ) : column.getIsSorted() === "asc" ? (
                <ArrowUp size={16} />
            ) : (
                <ArrowDown size={16} />
            )}
          </span>
                </button>
            ),
            cell: info => <span className="font-medium text-gray-900">{info.getValue()}</span>,
        }),
        columnHelper.accessor('accountNumber', {
            header: ({ column }) => (
                <button
                    className="flex items-center gap-2 hover:text-green-100 transition-colors duration-150 group w-full text-white text-base"
                    onClick={() => column.toggleSorting()}
                >
                    Account Number
                    <span className="text-gray-300 group-hover:text-white">
            {column.getIsSorted() === false ? (
                <ArrowUpDown size={16} />
            ) : column.getIsSorted() === "asc" ? (
                <ArrowUp size={16} />
            ) : (
                <ArrowDown size={16} />
            )}
          </span>
                </button>
            ),
            cell: info => <span className="font-medium text-gray-600">{info.getValue()}</span>,
        }),
        columnHelper.accessor('weeklyCollections', {
            header: ({ column }) => (
                <button
                    className="flex items-center gap-2 hover:text-green-100 transition-colors duration-150 group w-full text-white text-base"
                    onClick={() => column.toggleSorting()}
                >
                    Weekly Collections
                    <span className="text-gray-300 group-hover:text-white">
            {column.getIsSorted() === false ? (
                <ArrowUpDown size={16} />
            ) : column.getIsSorted() === "asc" ? (
                <ArrowUp size={16} />
            ) : (
                <ArrowDown size={16} />
            )}
          </span>
                </button>
            ),
            cell: info => (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
          {info.getValue()}
        </span>
            ),
        }),
        columnHelper.accessor('numberOfTenants', {
            header: ({ column }) => (
                <button
                    className="flex items-center gap-2 hover:text-green-100 transition-colors duration-150 group w-full text-white text-base"
                    onClick={() => column.toggleSorting()}
                >
                    Number of Tenants
                    <span className="text-gray-300 group-hover:text-white">
            {column.getIsSorted() === false ? (
                <ArrowUpDown size={16} />
            ) : column.getIsSorted() === "asc" ? (
                <ArrowUp size={16} />
            ) : (
                <ArrowDown size={16} />
            )}
          </span>
                </button>
            ),
            cell: info => (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
          {info.getValue()}
        </span>
            ),
        }),
        columnHelper.accessor('area', {
            header: ({ column }) => (
                <button
                    className="flex items-center gap-2 hover:text-green-100 transition-colors duration-150 group w-full text-white text-base"
                    onClick={() => column.toggleSorting()}
                >
                    Area
                    <span className="text-gray-300 group-hover:text-white">
            {column.getIsSorted() === false ? (
                <ArrowUpDown size={16} />
            ) : column.getIsSorted() === "asc" ? (
                <ArrowUp size={16} />
            ) : (
                <ArrowDown size={16} />
            )}
          </span>
                </button>
            ),
            cell: info => (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-purple-100 text-purple-800">
          {info.getValue() || 'N/A'}
        </span>
            ),
        }),
        columnHelper.accessor('collectionCategory', {
            header: ({ column }) => (
                <button
                    className="flex items-center gap-2 hover:text-green-100 transition-colors duration-150 group w-full text-white text-base"
                    onClick={() => column.toggleSorting()}
                >
                    Collection Category
                    <span className="text-gray-300 group-hover:text-white">
            {column.getIsSorted() === false ? (
                <ArrowUpDown size={16} />
            ) : column.getIsSorted() === "asc" ? (
                <ArrowUp size={16} />
            ) : (
                <ArrowDown size={16} />
            )}
          </span>
                </button>
            ),
            cell: info => (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
          {info.getValue()}
        </span>
            ),
        }),
    ];

    const filteredData = useMemo(() => {
        return customers.filter(customer => {
            const matchesSearch = !filters.search
                ? true
                : customer.name.toLowerCase().includes(filters.search.toLowerCase()) ||
                customer.accountNumber.toLowerCase().includes(filters.search.toLowerCase());

            const matchesArea = !filters.area
                ? true
                : customer.area === filters.area;

            const matchesCategory = !filters.collectionCategory
                ? true
                : customer.collectionCategory === filters.collectionCategory;

            const matchesCollections = !filters.weeklyCollections
                ? true
                : customer.weeklyCollections === parseInt(filters.weeklyCollections);

            return matchesSearch && matchesArea && matchesCategory && matchesCollections;
        });
    }, [filters, customers]);

    const handleFilterChange = (name: string, value: string) => {
        setFilters(prev => ({ ...prev, [name]: value }));
    };

    const table = useReactTable({
        data: filteredData,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <div className="flex flex-col h-[calc(100vh-8rem)] space-y-6 mb-8">
            <div className="flex-none">
                <h1 className="text-2xl font-bold text-gray-900">Customers</h1>
                <CustomerFilters filters={filters} onFilterChange={handleFilterChange} />
            </div>

            <div className="flex-1 bg-white rounded-lg shadow-lg overflow-hidden min-h-0 border border-gray-200">
                <div className="h-full overflow-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th
                                        key={header.id}
                                        className="sticky top-0 px-6 py-4 text-left font-bold tracking-wider bg-gray-900 cursor-pointer"
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {table.getRowModel().rows.map((row, index) => (
                            <tr
                                key={row.id}
                                className={`hover:bg-gray-400 transition-colors duration-150 ${
                                    index % 2 === 0 ? 'bg-white' : 'bg-gray-900/[0.1]'
                                }`}
                            >
                                {row.getVisibleCells().map(cell => (
                                    <td
                                        key={cell.id}
                                        className="px-6 py-4 whitespace-nowrap text-sm"
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}