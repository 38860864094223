import { Tenant } from '../types';

export const mockTenants: Tenant[] = [
    {
        id: '1',
        name: 'Office Building A',
        customerId: '1',
        isActive: true,
    },
    {
        id: '2',
        name: 'Retail Store B',
        customerId: '1',
        isActive: true,
    },
    {
        id: '3',
        name: 'Warehouse C',
        customerId: '2',
        isActive: false,
    },
];