import { Customer } from '../../types';
import { customers, searchCustomers, filterCustomers, getUniqueAreas, getUniqueCollectionCategories } from '../../data/mockData/customers';

export const customerService = {
    getCustomers(): Customer[] {
        return customers;
    },

    getCustomerById(id: string): Customer | undefined {
        return customers.find(customer => customer.id === id);
    },

    searchCustomers(query: string): Customer[] {
        return searchCustomers(query);
    },

    filterCustomers(filters: {
        area?: string;
        collectionCategory?: string;
        weeklyCollections?: number;
    }): Customer[] {
        return filterCustomers(filters);
    },

    getAreas(): (string | null)[] {
        return getUniqueAreas();
    },

    getCollectionCategories(): string[] {
        return getUniqueCollectionCategories();
    }
};