import React, { useState, useMemo } from 'react';
import { Download } from 'lucide-react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
} from '@tanstack/react-table';
import { Collection } from '../types';
import { mockCollections } from '../utils/mockData';
import { CollectionFilters } from '../components/collections/CollectionFilters';
import { ManifestModal } from '../components/collections/ManifestModal';
import { exportToExcel, exportManifestToPDF } from '../utils/export';
import { isWithinInterval, parseISO } from 'date-fns';

const columnHelper = createColumnHelper<Collection>();

const columns = [
    columnHelper.accessor('date', {
        header: 'Date',
        cell: info => <span className="font-bold">{info.getValue()}</span>,
    }),
    columnHelper.accessor('time', {
        header: 'Time',
        cell: info => <span className="font-bold">{info.getValue()}</span>,
    }),
    columnHelper.accessor('customerName', {
        header: 'Customer',
        cell: info => <span className="font-bold">{info.getValue()}</span>,
    }),
    columnHelper.accessor('bagsCollected', {
        header: 'Bags',
        cell: info => <span className="font-bold">{info.getValue()}</span>,
    }),
    columnHelper.accessor('binsCollected', {
        header: 'Bins',
        cell: info => <span className="font-bold">{info.getValue()}</span>,
    }),
    columnHelper.accessor('manifestNumber', {
        header: 'Manifest',
        cell: info => (
            <button
                onClick={() => (info.table.options.meta as { onManifestClick: (collection: Collection) => void })?.onManifestClick(info.row.original)}
                className="font-bold text-green-500 hover:text-green-600"
            >
                {info.getValue()}
            </button>
        ),
    }),
];

export function CollectionsPage() {
    const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null);
    const [filters, setFilters] = useState({
        dateRange: { start: '', end: '' },
        category: '',
        collectionsPerWeek: '',
        customerName: '',
        manifestNumber: '',
    });

    const filteredData = useMemo(() => {
        return mockCollections.filter(collection => {
            const matchesDateRange = !filters.dateRange.start || !filters.dateRange.end
                ? true
                : isWithinInterval(parseISO(collection.date), {
                    start: parseISO(filters.dateRange.start),
                    end: parseISO(filters.dateRange.end),
                });

            const matchesCustomer = !filters.customerName
                ? true
                : collection.customerName
                    .toLowerCase()
                    .includes(filters.customerName.toLowerCase());

            const matchesManifest = !filters.manifestNumber
                ? true
                : collection.manifestNumber
                    .toLowerCase()
                    .includes(filters.manifestNumber.toLowerCase());

            return matchesDateRange && matchesCustomer && matchesManifest;
        });
    }, [filters]);

    const handleFilterChange = (name: string, value: any) => {
        setFilters(prev => ({ ...prev, [name]: value }));
    };

    const table = useReactTable({
        data: filteredData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        meta: {
            onManifestClick: (collection: Collection) => setSelectedCollection(collection),
        },
    });

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold text-gray-900">Collections</h1>
                <button
                    onClick={() => exportToExcel(filteredData)}
                    className="flex items-center gap-2 bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
                >
                    <Download size={20} />
                    Export to Excel
                </button>
            </div>

            <CollectionFilters filters={filters} onFilterChange={handleFilterChange} />

            <div className="bg-white rounded-lg shadow overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th
                                    key={header.id}
                                    className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id} className="hover:bg-gray-50">
                            {row.getVisibleCells().map(cell => (
                                <td
                                    key={cell.id}
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {selectedCollection && (
                <ManifestModal
                    collection={selectedCollection}
                    onClose={() => setSelectedCollection(null)}
                    onExport={() => exportManifestToPDF(selectedCollection)}
                />
            )}
        </div>
    );
}