import React from 'react';
import { X } from 'lucide-react';
import { Collection } from '../../types';

interface ManifestModalProps {
    collection: Collection;
    onClose: () => void;
    onExport: () => void;
}

export function ManifestModal({ collection, onClose, onExport }: ManifestModalProps) {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
                <div className="p-6">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-2xl font-bold">Collection Manifest</h2>
                        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                            <X size={24} />
                        </button>
                    </div>

                    <div className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p className="text-sm font-bold text-gray-600">Manifest Number</p>
                                <p className="text-lg font-bold">{collection.manifestNumber}</p>
                            </div>
                            <div>
                                <p className="text-sm font-bold text-gray-600">Date & Time</p>
                                <p className="text-lg font-bold">{`${collection.date} ${collection.time}`}</p>
                            </div>
                        </div>

                        <div>
                            <p className="text-sm font-bold text-gray-600">Customer</p>
                            <p className="text-lg font-bold">{collection.customerName}</p>
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p className="text-sm font-bold text-gray-600">Bags Collected</p>
                                <p className="text-lg font-bold">{collection.bagsCollected}</p>
                            </div>
                            <div>
                                <p className="text-sm font-bold text-gray-600">Bins Collected</p>
                                <p className="text-lg font-bold">{collection.binsCollected}</p>
                            </div>
                        </div>

                        <div>
                            <p className="text-sm font-bold text-gray-600">Collection Location</p>
                            <p className="text-lg font-bold">
                                {`${collection.latitude}, ${collection.longitude}`}
                            </p>
                        </div>

                        <div>
                            <p className="text-sm font-bold text-gray-600">Representative</p>
                            <p className="text-lg font-bold">{collection.representativeName}</p>
                        </div>
                    </div>

                    <div className="mt-8 flex justify-end">
                        <button
                            onClick={onExport}
                            className="bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
                        >
                            Export PDF
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}