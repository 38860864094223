import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Eye, EyeOff, Loader } from 'lucide-react';

export function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { login, isLoading } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault();
            setError('');

            if (!username.trim() || !password.trim()) {
                setError('Please enter both username and password');
                return;
            }

            try {
                await login({
                    username: username.trim(),
                    password: password.trim(),
                });
                navigate('/dashboard');
            } catch (err) {
                setError(
                    err instanceof Error
                        ? err.message
                        : 'An unexpected error occurred. Please try again.'
                );
                console.error('Login submission error:', err);
            }
        },
        [username, password, login, navigate]
    );

    const togglePasswordVisibility = useCallback(() => {
        setShowPassword((prev) => !prev);
    }, []);

    return (
        <div className="min-h-screen bg-gray-900 flex items-center justify-center">
            <div className="bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md">
                {/* Logo */}
                <div className="flex justify-center mb-8">
                    <img src="/logo.png" alt="Logo" className="w-32 h-22" />
                </div>

                {/* Title */}
                <h1 className="text-3xl font-bold text-white text-center mb-8">
                    KDM Portal
                </h1>

                {/* Form */}
                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Error Message */}
                    {error && (
                        <div className="bg-red-500 text-white p-3 rounded text-center font-bold">
                            {error}
                        </div>
                    )}

                    {/* Username Input */}
                    <div>
                        <label className="block text-white font-bold mb-2">
                            Username
                        </label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full p-3 rounded bg-gray-700 text-white border border-gray-600 focus:border-green-500 focus:ring-1 focus:ring-green-500"
                            required
                            disabled={isLoading}
                            autoComplete="username"
                            autoFocus
                        />
                    </div>

                    {/* Password Input */}
                    <div>
                        <label className="block text-white font-bold mb-2">
                            Password
                        </label>
                        <div className="relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full p-3 rounded bg-gray-700 text-white border border-gray-600 focus:border-green-500 focus:ring-1 focus:ring-green-500"
                                required
                                disabled={isLoading}
                                autoComplete="current-password"
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white focus:outline-none"
                                disabled={isLoading}
                            >
                                {showPassword ? (
                                    <EyeOff size={20} />
                                ) : (
                                    <Eye size={20} />
                                )}
                            </button>
                        </div>
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-full bg-green-500 text-white font-bold py-3 px-4 rounded hover:bg-green-600 transition duration-200 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <>
                                <Loader className="animate-spin -ml-1 mr-3 h-5 w-5" />
                                Logging in...
                            </>
                        ) : (
                            'Login'
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
}
