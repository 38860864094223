import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import { User, LoginCredentials } from '../types/auth';
import { authApi, ApiError } from '../services/api';
import { storage } from '../services/storage';

interface AuthContextType {
    user: User | null;
    login: (credentials: LoginCredentials) => Promise<void>;
    logout: () => Promise<void>;
    isLoading: boolean;
    error: string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
    const [user, setUser] = useState<User | null>(() => {
        if (storage.hasValidSession()) {
            const { username } = storage.getSessionData();
            return { username: username!, isAuthenticated: true };
        }
        return null;
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const login = useCallback(async (credentials: LoginCredentials) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await authApi.login(credentials);

            if (response.status === 'success' && response.session_token && response.user_id) {
                storage.setSessionData(
                    response.session_token,
                    response.user_id.toString(),
                    credentials.username
                );

                setUser({
                    username: credentials.username,
                    isAuthenticated: true,
                });
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            console.error('Login error:', error);

            let errorMessage = 'An unexpected error occurred. Please try again.';

            if (error instanceof ApiError) {
                errorMessage = error.message;
            } else if (error instanceof Error) {
                errorMessage = error.message;
            }

            setError(errorMessage);
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const logout = useCallback(async () => {
        setIsLoading(true);
        setError(null);

        try {
            const { token, userId } = storage.getSessionData();
            if (token && userId) {
                await authApi.logout(token, userId);
            }
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            storage.clearSessionData();
            setUser(null);
            setIsLoading(false);
        }
    }, []);

    return (
        <AuthContext.Provider value={{ user, login, logout, isLoading, error }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}