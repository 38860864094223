import { LoginCredentials, LoginResponse } from '../../types/auth';
import { AUTH_CONFIG } from '../../config/auth';

export async function mockLogin(credentials: LoginCredentials): Promise<LoginResponse> {
    // Simulate network delay
    await new Promise(resolve => setTimeout(resolve, 800));

    const { username, password } = AUTH_CONFIG.MOCK_CREDENTIALS;

    if (credentials.username === username && credentials.password === password) {
        return {
            status: 'success',
            message: 'Login successful',
            session_token: 'mock-session-token',
            user_id: 1
        };
    }

    throw new Error('Invalid username or password');
}

export async function mockLogout(): Promise<void> {
    // Simulate network delay
    await new Promise(resolve => setTimeout(resolve, 500));
}