import React from 'react';
import { clsx } from 'clsx';

interface StatusBadgeProps {
    isActive: boolean;
}

export function StatusBadge({ isActive }: StatusBadgeProps) {
    return (
        <span
            className={clsx(
                'px-3 py-1 rounded-full text-sm font-bold',
                isActive
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800'
            )}
        >
      {isActive ? 'Active' : 'Inactive'}
    </span>
    );
}