const STORAGE_KEYS = {
    SESSION_TOKEN: 'sessionToken',
    USER_ID: 'userId',
    USERNAME: 'username',
} as const;

export const storage = {
    setSessionData(token: string, userId: string, username: string) {
        localStorage.setItem(STORAGE_KEYS.SESSION_TOKEN, token);
        localStorage.setItem(STORAGE_KEYS.USER_ID, userId);
        localStorage.setItem(STORAGE_KEYS.USERNAME, username);
    },

    clearSessionData() {
        Object.values(STORAGE_KEYS).forEach(key => localStorage.removeItem(key));
    },

    getSessionData() {
        return {
            token: localStorage.getItem(STORAGE_KEYS.SESSION_TOKEN),
            userId: localStorage.getItem(STORAGE_KEYS.USER_ID),
            username: localStorage.getItem(STORAGE_KEYS.USERNAME),
        };
    },

    hasValidSession() {
        const { token, username } = this.getSessionData();
        return Boolean(token && username);
    },
};