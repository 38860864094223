import { LoginCredentials, LoginResponse } from '../types/auth';
import { mockLogin, mockLogout } from './auth/mockAuth';

export class ApiError extends Error {
    constructor(public status: number, message: string) {
        super(message);
        this.name = 'ApiError';
    }
}

export const authApi = {
    async login(credentials: LoginCredentials): Promise<LoginResponse> {
        try {
            // Using mock authentication instead of actual API call
            return await mockLogin(credentials);

            /* Commented out actual API implementation
            const response = await fetch(`${API_BASE_URL}/temporary/tempLogin.php`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              },
              body: JSON.stringify(credentials),
              mode: 'cors',
            });

            return handleResponse<LoginResponse>(response);
            */
        } catch (error) {
            if (error instanceof Error) {
                throw new ApiError(401, error.message);
            }
            throw new ApiError(500, 'An unexpected error occurred. Please try again.');
        }
    },

    async logout(sessionToken: string, userId: string): Promise<void> {
        try {
            // Using mock logout instead of actual API call
            return await mockLogout();

            /* Commented out actual API implementation
            const response = await fetch(`${API_BASE_URL}/temporary/logout.php`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              },
              body: JSON.stringify({ session_token: sessionToken, user_id: userId }),
              mode: 'cors',
            });

            return handleResponse(response);
            */
        } catch (error) {
            throw new ApiError(500, 'Unable to complete logout. Please try again.');
        }
    },
};